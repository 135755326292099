<template>
    <div
        class="container-fluid"
        :style="{
            backgroundImage: `url(${require('@/assets/gedung.jpeg')})`,
        }"
    ></div>
    <!-- <h1 class="title text-center py-4">SSO POLTEKES</h1> -->
    <div class="row bg-text">
        <div class="col d-flex justify-content-center">
            <div class="card cb1 m-2 text-center">
                <div class="card-body">
                    <!-- <span class="card-number">01</span> -->
                    <img src="@/assets/logo-poltekes.jpg" alt="" />
                    <h5 class="SSO mb-4">SIBILLING</h5>
                    <h5 class="SSO mb-4">Poltekes Semarang</h5>
                    <div class="mb-3 mt-3">
                        <div class="d-flex justify-content-center">
                            <div class="col-12">
                                <progress-spinner strokeWidth="8" animationDuration=".5s"></progress-spinner>
                            </div>
                            <div class="col-12">
                                <h1>Mohon Tunggu...</h1>
                            </div>
                        </div>
                    </div>
                    <Dialog v-model:visible="failed" :style="{ width: '450px' }" header="Confirm" :modal="true">
                        <div class="flex align-items-center justify-content-center">
                            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                            <span>Maaf anda tidak dapat akses masuk halaman ini, Silahkan login kembali.</span>
                        </div>
                        <template #footer>
                            <Button label="Login" icon="pi pi-check" class="p-button-text" @click="gotologin" />
                        </template>
                    </Dialog>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import qs from 'qs';
import stores from '../store';
// import ipsimpeg from '@/axios/index.js';
// import moment from 'moment';
// import 'moment/locale/id';
const route = useRoute();
const router = useRouter();
const token = ref(null);
const refresh = ref(null);
const failed = ref(false);
const getQuery = async () => {
    // console.log(route.query);
    if (route.query.token) {
        token.value = route.query.token;
        refresh.value = route.query.refresh;
        // console.log(token.value, 'ini token');
        // console.log(refresh.value, 'ini refresh');
        await getVerified();
    } else {
        router.push('https://sso.poltekkes-smg.ac.id/client_login/');
    }
};

const getVerified = async () => {
    let ipsso = 'https://sso.poltekkes-smg.ac.id/api/';
    let datas = {
        client_id: 'a5e1397c-c227-42c4-b188-0297f9afa990',
        grant_type: 'refresh_token',
        client_secret: 'SSO',
        refresh_token: refresh.value,
    };
    let res = await axios.post(ipsso + 'oauth/token', qs.stringify(datas));
    // console.log(res,'iki');
    if (res.status == 200) {
        stores.dispatch('save_token_login', res.data);
        await cekUser();
    } else if (res.status == 400) {
        console.log('gagal');
    } else {
        console.log('failed');
        failed.value = true;
    }
};

const cekUser = async () => {
    // let ip = 'http://localhost:8107/';
    let ip = 'https://billing.poltekkes-smg.ac.id/api/';
    let token = stores.state.billing.sso_access_token;
    // let token = stores.state.sso_access_token;
    // console.log('iki', token);
    let res = await axios.get(ip + 'masterUser/cekUser', { headers: { token: `${token}` } });
    console.log(res.data, 'data users');
    if (res.data.status == 200 && res.data.message == 'sukses') {
        stores.commit('set_user', res.data.data[0]);
        setTimeout(() => {
            router.push({ path: '/dashboard' });
        }, 3000);
    } else {
        setTimeout(() => {
            failed.value = true;
            router.push({ path: '/access' });
        }, 3000);
    }
    // console.log(res, 'ini res cekuser');
};
const gotologin = () => {
    setTimeout(() => {
        window.location.href = 'https://sso.poltekkes-smg.ac.id/client_login/';
    }, 3000);
};
``;
onMounted(async () => {
    await getQuery();
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* {
    /* margin: 0;
      padding: 0; */
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.container-fluid {
    /* background-image: url('@/assets/gedung.jpeg') */
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
    /* background-color:  rgba(225, 225, 225, 0.1); */
    padding: 7%;
    filter: blur(1rem);

    /* color: aqua; */
}

.SSO {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 0px;
}
.title {
    margin: 0 auto;
    width: 100%;
    max-width: 40rem;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    color: #667085;
}

.alert {
    font-size: 10px;
    color: red;
}

.card {
    width: 90%;
    max-width: 440px;
    padding: 5rem 2.5rem;
    height: 600px;
    color: black;
    border-radius: 1rem;
    border: 1px solid transparent;
    /* background-color: rgba(225, 225, 225, 0.1); */
    background-color: #ffffff;

    backdrop-filter: blur(1rem);
    box-shadow: 1.3rem 1.3rem 1.3rem 1.3rem rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* border-top-color: rgba(225, 225, 225, 0.5);
    border-left-color: rgba(225, 225, 225, 0.5);
    border-bottom-color: rgba(225, 225, 225, 0.1);
    border-right-color: rgba(225, 225, 225, 0.1); */
}

.card:hover {
    width: 90%;
    max-width: 440px;
    padding: 5rem 2.5rem;
    height: 600px;

    border-radius: 1rem;
    border: 1px solid transparent;
    color: black;
    background-color: #ffffff;
    /* background-color: linear-gradient(
      to right bottom,
      rgba(225, 225, 225, 0.5),
      rgba(225, 225, 225, 0.1)
    ); */

    backdrop-filter: blur(1rem);
    box-shadow: 1.3rem 1.3rem 1.3rem 1.3rem rgba(0, 0, 0, 0.5);

    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* border-top-color: rgba(225, 225, 225, 0.5);
    border-left-color: rgba(225, 225, 225, 0.5);
    border-bottom-color: rgba(225, 225, 225, 0.1);
    border-right-color: rgba(225, 225, 225, 0.1); */
}
.btn {
    width: 100%;
    background-color: #027a48;
    color: black;
    letter-spacing: 1px;
}

.btn:hover {
    width: 100%;
    background-color: #027a48;
    color: #ffffff;
    letter-spacing: 1px;
}
/* .register:hover {
    transform: scale(1.1);
    font-size: 16px;
    font-weight: 500;
    color: blue;
  } */
</style>

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {billing} from "./billing.js"
// import ip from "@/ip.js";


const billing_data = createPersistedState({
  path:['state'],
  key:'billing'
})
// const plugins = [createPersistedState()];
export default new createStore({
  modules:{
    billing
  },
  plugins:[billing_data]
});


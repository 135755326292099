<template>
    <div class="layout-breadcrumb-container">
        <div class="layout-breadcrumb-left-items">
            <a href="#" class="menu-button" @click="onMenuButtonClick($event)" v-if="isStatic()">
                <i class="pi pi-bars"></i>
            </a>

            <!-- <Breadcrumb :model="items" class="layout-breadcrumb"></Breadcrumb> -->
        </div>
        <!-- <div class="layout-breadcrumb-right-items">
			<a tabindex="0" class="search-icon" @click="breadcrumbClick">
				<i class="pi pi-search"></i>
			</a>

			<div class="search-wrapper" :class="{'active-search-wrapper': searchActive}">
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
					<InputText placeholder="Search..." v-model="search" @click="inputClick"/>
				</span>
			</div>

			<span class="layout-rightmenu-button-desktop">
				<Button label="Today" icon="pi pi-bookmark"
					class="layout-rightmenu-button" @click="onRightMenuButtonClick()"></Button>
			</span>-->

        <span class="layout-rightmenu-button-desktop">
            <Button label="Today" icon="pi pi-bookmark" class="layout-rightmenu-button" @click="onRightMenuButtonClick()">{{ times }}</Button>
        </span>
        <!--<span class="layout-rightmenu-button-mobile">
				<Button icon="pi pi-bookmark"
					class="layout-rightmenu-button" @click="onRightMenuButtonClick()"></Button>
			</span>
		</div>-->
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/dist/locale/id';
export default {
    props: {
        menuMode: String,
        searchActive: {
            type: Boolean,
            default: false,
        },
        searchClick: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['menubutton-click', 'rightmenu-button-click', 'update:searchActive', 'update:searchClick'],
    data() {
        return {
            items: [],
            search: '',
            moment,
            now: '',
        };
    },
    watch: {
        $route() {
            this.watchRouter();
        },
        semester_aktif: function (newV, oldV) {
            if (newV != oldV) {
                this.get_kalender();
            }
        },
    },
    created() {
        this.watchRouter();
        this.setTimer();
        this.get_data();
    },
    computed: {
        times() {
            return moment(this.now).format('lll');
        },
        semester_aktif() {
            return this.$store.state.billing.semester_aktif ? this.$store.state.billing.semester_aktif.semester_id : null;
        },
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = [];
                const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
                for (let pro in bc) {
                    this.items.push({ label: bc[pro] });
                }
            }
        },
        async get_data() {
            let vm = this;
            try {
                let semester_aktif = await vm.$axiossimadu('semester/listSemesterAKtif');
                // console.log(semester_aktif.data.data[0], 'semester aktif');
                vm.$store.commit('set_semester_aktif', semester_aktif.data.data[0]);

                let kalender = await vm.$axiossimadu.post('kalenderAkademik/listKalenderAkademikBySemesterId', {
                    semester_id: vm.semester_aktif,
                });
                // console.log(kalender, 'kalender akademik mahasiswa');
                // vm.$store.commit('set_kalender', kalender.data.data);
                let kal = {};
                kalender.data.data.forEach((el) => {
                    kal[el.nama_kegiatan_akademik] = el;
                });
                vm.$store.commit('set_kalender_aktif', kal);
            } catch (err) {
                console.log(err);
            }
        },
        async get_kalender() {
            let vm = this;
            try {
                let kalender = await vm.$axiossimadu.post('kalenderAkademik/listKalenderAkademikBySemesterId', {
                    semester_id: vm.semester_aktif,
                });
                // console.log(kalender, 'kalender akademik mahasiswa');
                // vm.$store.commit('set_kalender', kalender.data.data);
                let kal = {};
                kalender.data.data.forEach((el) => {
                    kal[el.nama_kegiatan_akademik] = el;
                });
                vm.$store.commit('set_kalender_aktif', kal);
            } catch (err) {
                console.log(err);
            }
        },
        setTimer() {
            let vm = this;
            let interval = setInterval(() => {
                vm.now = moment();
            }, 1000);
            vm.interval = interval;
            clearInterval(interval - 1);
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onRightMenuButtonClick() {
            this.$emit('rightmenu-button-click');
        },
        inputClick() {
            this.$emit('update:searchClick', true);
        },
        breadcrumbClick() {
            this.$emit('update:searchActive', true);
            this.$emit('update:searchClick', true);
        },
        isStatic() {
            return this.menuMode === 'static';
        },
    },
};
</script>

<style scoped>
::v-deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) {
    display: none;
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index.js';

const routes = [
    {
        path: '/',
        name: 'Home',
        exact: true,
        component: () => import('./pages/login_billing.vue'),
    },
    {
        path: '/login',
        name: 'Login Billing',
        exact: true,
        component: () => import('./pages/login_billing.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        exact: true,
        component: () => import('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
            requiresAuth: true,
        },
    },
    {
        path: '/authentification',
        name: 'Authentification',
        component: () => import('./pages/LandingPage.vue'),
    },
    {
        path: '/jenis_kategori_bayar',
        name: 'Landing Page',
        component: () => import('./views/Master_Data/jenis_kategori_bayar/jenis_kategori_bayar.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/pengembalian_dana',
        name: 'Pengembalian Dana',
        component: () => import('./views/Other/pengembalian/pengembalian.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/pencarian_mahasiswa',
        name: 'Pencarian Mahasiswa',
        component: () => import('./views/pencarian_mahasiswa/pencarian_mahasiswa.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/bank',
        name: 'BANK',
        component: () => import('./views/Master_Data/bank/bank.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/master_maba',
        name: 'Master MABA',
        component: () => import('./views/Master_Mahasiswa/master_maba/master_maba.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/master_mahasiswa',
        name: 'Master Mahasiswa',
        component: () => import('./views/Master_Mahasiswa/master_mahasiswa/master_mahasiswa.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/arsip_mahasiswa',
        name: 'Arsip Mahasiswa',
        component: () => import('./views/Master_Mahasiswa/arsip_mahasiswa/arsip_mahasiswa.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/usulan_biaya',
        name: 'Usulan Biaya',
        component: () => import('./views/Master_Biaya/usulan_biaya/usulan_biaya.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/kategori_biaya',
        name: 'Kategori Biaya',
        component: () => import('./views/Master_Biaya/kategori_biaya/kategori_biaya.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rekap_tagihan_pmb',
        name: 'Rekap Tagihan PMB',
        component: () => import('./views/Modul_Laporan/rekap_tagihan_pmb/rekap_tagihan_pmb.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rekap_tagihan_maba',
        name: 'Rekap Tagihan MABA',
        component: () => import('./views/Modul_Laporan/rekap_tagihan_maba/rekap_tagihan_maba.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rekap_tagihan_mahasiswa',
        name: 'Rekap Tagihan Mahasiswa',
        component: () => import('./views/Modul_Laporan/rekap_tagihan_mahasiswa/rekap_tagihan_mahasiswa.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rekap_piutang_mahasiswa',
        name: 'Rekap Piutang Mahasiswa',
        component: () => import('./views/Modul_Laporan/rekap_piutang_mahasiswa/rekap_piutang_mahasiswa.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rekap_laporan_gakin',
        name: 'Rekap Laporan Gakin',
        component: () => import('./views/Modul_Laporan/rekap_laporan_gakin/rekap_laporan_gakin.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rekap_tagihan_prodi',
        name: 'Rekap Tagihan Prodi',
        component: () => import('./views/Modul_Laporan/rekap_tagihan_prodi/rekap_tagihan_prodi.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/penetapan_tahun_ajaran',
        name: 'Penetapan Tahun Ajaran',
        component: () => import('./views/Modul_Penetapan/penetapan_tahun_ajaran/penetapan_tahun_ajaran.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/penetapan_kalender_bayar',
        name: 'Penetapan Kalender Bayar',
        component: () => import('./views/Modul_Penetapan/penetapan_kalender_bayar/penetapan_kalender_bayar.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/penetapan_kategori_bayar',
        name: 'Penetapan Kategori Bayar',
        component: () => import('./views/Modul_Penetapan/penetapan_kategori_bayar/penetapan_kategori_bayar.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/penetapan_kalender_bayar_pmb',
        name: 'Penetapan Kalender Bayar PMB',
        component: () => import('./views/Modul_Penetapan/penetapan_kalender_bayar_pmb/penetapan_kalender_bayar_pmb.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/penetapan_biaya_studi',
        name: 'Penetapan Biaya Studi',
        component: () => import('./views/Modul_Penetapan/penetapan_biaya_studi/penetapan_biaya_studi.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/master_biaya_semester',
        name: 'Master Biaya Semester',
        component: () => import('./views/Master_Biaya/master_biaya_semester/master_biaya_semester.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/beasiswa_bidik_kamu',
        name: 'Beasiswa Bidik Kamu',
        component: () => import('./views/Beasiswa/bidik_kamu/bidik_kamu.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/beasiswa_gakin',
        name: 'Beasiswa Gakin',
        component: () => import('./views/Beasiswa/gakin/gakin.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/pelunasan_beasiswa',
        name: 'Beasiswa Pelunasan',
        component: () => import('./views/Beasiswa/pelunasan_beasiswa/pelunasan_beasiswa.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/wisuda',
        name: 'Beasiswa',
        component: () => import('./views/Other/wisuda/wisuda.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/users',
        name: 'User Sibilling',
        component: () => import('./views/Other/user/user.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/access',
        name: 'Access',
        exact: true,
        component: () => import('./pages/Access.vue'),
    },
    {
        path: '/sync_tagihan',
        name: 'Sync',
        exact: true,
        component: () => import('./views/Sync_Tagihan/sync_tagihan.vue'),
    },
    {
        path: '/logout',
        name: 'Logout',
        exact: true,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // let allow = !to.meta.level || to.meta.level.includes(store.state.simpeg.user_level.level)
    let token = store.state.billing.sso_access_token;
    if (
        to.matched.some((r) => {
            if (r.meta.requiresAuth) {
                if (token) {
                    if (r.path == '/logout') {
                        store.commit('set_clear_token');
                        next('/login');
                    } else {
                        next();
                    }
                } else {
                    next('/login');
                }
            } else {
                if (token) {
                    if (r.path == '/login' || r.path == '/') {
                        next('/dashboard');
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            }
        })
    );
});

export default router;
